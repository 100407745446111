<template>
    <edit-view
        :view="editView"
        :allow-delete="name === undefined"
        v-on:update-view="updateViewToProject"
        v-on:delete-view="deleteViewToProject"
        v-on:cancel="cancelUpdate"
    />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import editView from '@/views/views/edit';

export default {
    name: 'project-view-edit',
    data: () => ({
        editView: undefined,
    }),
    props: {},
    components: {
        editView,
    },
    mounted() {
        this.initEditingView();
    },
    props: {
        name: {
            default: undefined,
        },
    },
    watch: {
        project: function (value) {
            if (value) {
                this.initEditingView();
            }
        },
    },
    computed: {
        ...mapGetters(['project']),
    },
    methods: {
        ...mapActions(['updateProjectView', 'deleteProjectView']),
        initEditingView() {
            if (this.project) {
                if (this.name) {
                    this.editView = this.project.views.find((v) => v.name === this.name);
                } else {
                    this.editView = this.project.views.find((v) => v.id === this.$route.params.view_id);
                }
            }
        },
        cancelUpdate() {
            if (!this.name) {
                this.$router.go(-1);
            }
        },
        deleteViewToProject(view) {
            const payload = {
                project: this.project.id,
                view: view,
            };

            this.deleteProjectView(payload);
            if (!this.name) {
                this.$router.go(-1);
            }
        },
        updateViewToProject(view) {
            const payload = {
                project: this.project.id,
                view: view,
            };

            this.updateProjectView(payload);
            if (!this.name) {
                this.$router.go(-1);
            }
        },
    },
};
</script>
